<template>
  <div>
    <b-card class="mt-1 border border-dark shadow-none">

      <CommissionsSellerSearcherFilters
        v-if="!isCommissionEditing"
        class="mb-2"
        @on:search="loadSellerData"
      />
      <CommissionsSellerSearcherDetailsInfo
        v-if="showSellerData"
        :date="sellerDate"
        :name="sellerName"
        :email="sellerEmail"
      />
      <CommissionsSellerSearcherDetailsEmpty v-else />
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { BCard } from 'bootstrap-vue'
import CommissionsSellerSearcherFilters from '@/modules/trade/commissions/components/form/CommissionsSellerSearcherFilters.vue'
import CommissionsSellerSearcherDetailsInfo from '@/modules/trade/commissions/components/form/CommissionsSellerSearcherDetailsInfo.vue'
import CommissionsSellerSearcherDetailsEmpty from '@/modules/trade/commissions/components/form/CommissionsSellerSearcherDetailsEmpty.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    CommissionsSellerSearcherFilters,
    CommissionsSellerSearcherDetailsInfo,
    CommissionsSellerSearcherDetailsEmpty,
  },
  data() {
    return {
      sellerName: '',
      sellerDate: '',
      sellerEmail: '',

      showSellerData: false,
    }
  },
  computed: {
    ...mapGetters({
      getCommission: 'commissions/getCommission',
      getIsCommissionEditing: 'commissions/getIsCommissionEditing',
      /**
       * Filtros
       */
      getSelectedCommissionSeller: 'commissions/getSelectedCommissionSeller',
      getSelectedCommissionMonthAndYear: 'commissions/getSelectedCommissionMonthAndYear',
    }),
    /**
     * Comisión actual
     */
    commission: {
      get() { return this.getCommission },
    },
    /**
     * Indica si el modo de edición está activo
     */
    isCommissionEditing: {
      get() { return this.getIsCommissionEditing },
    },
    /**
     * Filtro de creador (filtro en store)
     */
    filteredSeller: {
      get() { return this.getSelectedCommissionSeller },
    },
    /**
     * Filtro de mes y año (filtro en store)
     */
    filteredMonthAndYear: {
      get() { return this.getSelectedCommissionMonthAndYear },
    },
  },

  /**
   * Hook que se ejecuta cuando se monta el componente
   * @summary Carga los datos de los vendedor
   */
  mounted() {
    if (this.isCommissionEditing) {
      this.sellerName = this.commission?.seller
      this.sellerEmail = this.commission?.email_seller
      this.sellerDate = this.commission?.month

      this.showSellerData = true
    }
  },

  methods: {
    /**
    * Muestra la información del vendedor seleccionado, sus órdenes y devoluciones
    * @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
    * @param {ParamDataTypeHere} parameterNameHere - Brief description of the parameter here. Note: For other notations of data types, please refer to JSDocs: DataTypes command.
    * @return {ReturnValueDataTypeHere} Brief description of the returning value here.
    */
    loadSellerData() {
      if (!this.filteredSeller) {
        this.showToast('Error de validación', 'No hay vendedor seleccionado.', 'warning')
        return
      }

      if (!this.filteredMonthAndYear) {
        this.showToast('Error de validación', 'No hay fecha seleccionada.', 'warning')
        return
      }

      this.sellerName = this.filteredSeller.name
      this.sellerEmail = this.filteredSeller.email
      this.sellerDate = this.filteredMonthAndYear.format('YYYY-MM-DD')

      this.showSellerData = true
      this.$emit('on:load-lists-data')
    },

    /**
    * Mensaje de alerta
    * @summary Muestra un mensaje de alerta personalizado
    * @param {string} title - Título del mensaje
    * @param {string} text - Texto del mensaje
    * @param {string} variant - Tipo de mensaje
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
