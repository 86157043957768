<template>
  <div>

    <template v-if="isLoadingFormData">
      <BasicSkeleton height="200px" />
      <BasicSkeleton height="250px" />
      <BasicSkeleton height="250px" />
    </template>

    <template v-else>

      <!-- #region::Main content form -->
      <div class="commission-details">
        <CommissionsSellerSearcherForm @on:load-lists-data="loadListData" />
        <CommissionsOrdersList
          v-show="canShowLists"
          ref="commissions-orders-list"
        />
        <CommissionsRefundsList
          v-show="canShowLists"
          ref="commissions-refunds-list"
          class="mt-3"
        />
      </div>
      <!-- #endregion::Main content form -->

      <!-- #region::Resumen commision -->
      <div class="resumen-commission border border-dark shadow-none">
        <CommissionsResumenForm />
      </div>
      <!-- #endregion::Resumen commision -->

    </template>

  </div>
</template>

<script>
import { mapActions } from 'vuex'

import CommissionsOrdersList from '@/modules/trade/commissions/components/lists/CommissionsOrdersList.vue'
import CommissionsRefundsList from '@/modules/trade/commissions/components/lists/CommissionsRefundsList.vue'
import CommissionsResumenForm from '@/modules/trade/commissions/components/form/CommissionsResumenForm.vue'
import CommissionsSellerSearcherForm from '@/modules/trade/commissions/components/form/CommissionsSellerSearcherForm.vue'

import BasicSkeleton from '@/components/cards/BasicSkeleton.vue'

export default {
  components: {
    BasicSkeleton,
    CommissionsOrdersList,
    CommissionsRefundsList,
    CommissionsResumenForm,
    CommissionsSellerSearcherForm,
  },
  props: {
    /**
     * Identificador de la comisión
     */
    id: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      /**
       * Determina cuando se pueden mostrar las listas de órdenes y devoluciones
       */
      canShowLists: false,
      /**
       * Indica si se están cargando los datos del formulario
       */
      isLoadingFormData: false,
    }
  },

  /**
   * Hook que se ejecuta antes de destruir el componente
   * @summary Limpia los datos de los filtros y el porcentaje de comisión
   */
  beforeDestroy() {
    // Limpia el campo de vendedor
    this.$store.dispatch('commissions/setSelectedCommissionSeller', '')
    // Limpia el mes y año
    this.$store.dispatch('commissions/setSelectedCommissionMonthAndYear', '')
    // Limpia el porcentaje de comisión
    this.$store.commit('commissions/setCommissionPercentage', 0)

    if (this.id) {
      // Limpia los datos de la comisión
      this.$store.commit('commissions/setCommission', null)
      // Limpia las listas de items seleccionados
      this.$store.commit('commissions/clearSelectedItemsListsFromCommission')
      // Establece el modo edición en falso
      this.$store.commit('commissions/setIsCommissionEditing', false)
    }
  },

  /**
   * Hook que se ejecuta cuando se monta el componente
   * @summary Carga los datos de los vendedores sugeridos
   */
  async mounted() {
    this.isLoadingFormData = true

    // Si se recibe un id, se establece el modo de edición en verdadero y se carga la
    // información de la comisión
    if (this.id) {
      // Establece el modo de edición en verdadero
      this.$store.commit('commissions/setIsCommissionEditing', true)
      await this.$store.dispatch('commissions/loadCommission', this.id)

      this.canShowLists = true
      await this.$refs['commissions-orders-list']?.loadOrdersListWithVerification()
      await this.$refs['commissions-refunds-list']?.loadRefundsListWithVerification()
    } else {
      // Carga los datos de los vendedores sugeridos
      await this.loadSellersSuggests()
    }

    this.isLoadingFormData = false
  },
  methods: {
    ...mapActions({
      loadSellersSuggests: 'commissions/loadSellersSuggests',
      clearSelectedItemsListsFromCommission: 'commissions/clearSelectedItemsListsFromCommission',
    }),

    /**
     * Carga los datos de las listas de órdenes y devoluciones
     * @summary Limpiar las listas de órdenes y devoluciones seleccionadas y determina
     * cuando se pueden mostrar las listas
     */
    async loadListData() {
      this.clearSelectedItemsListsFromCommission()
      this.canShowLists = true
      await this.$refs['commissions-orders-list'].loadOrdersListWithVerification()
      await this.$refs['commissions-refunds-list'].loadRefundsListWithVerification()
    },
  },
}
</script>
