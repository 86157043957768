<template>
  <div>
    <b-form-row>

      <!-- #region::Seller searcher -->
      <b-col cols="8">

        <vue-autosuggest
          v-model="sellerName"
          :suggestions="sellersOptions"
          :limit="5"
          :input-props="{
            id:'autosuggest_seller_searcher',
            class:'form-control',
            placeholder:'Buscar vendedor'
          }"
          @input="onSellerNameChange"
          @selected="filteredSeller = $event.item"
        >
          <template slot-scope="{suggestion}">
            <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
          </template>
        </vue-autosuggest>

      </b-col>
      <!-- #endregion::Seller searcher -->

      <!-- #region::Month picker -->
      <b-col md="2">
        <vue-monthly-picker
          v-model="filteredMonthAndYear"
          place-holder="Mes"
          date-format="MMMM yyyy"
          :month-labels="monthLabels"
          :input-class="['month-picker-style']"
          :min="minDate"
        />
      </b-col>
      <!-- #endregion::Month picker -->

      <!-- #region::Action button -->
      <b-col md="2">
        <b-button
          variant="secondary"
          class="w-100 mx-50"
          @click="$emit('on:search')"
        >
          <feather-icon
            icon="SearchIcon"
            class="mr-50"
          />
          <span class="align-middle">Buscar</span>
        </b-button>
      </b-col>
    <!-- #endregion::Action button -->

    </b-form-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { BCol, BButton, BFormRow } from 'bootstrap-vue'

import { VueAutosuggest } from 'vue-autosuggest'
import VueMonthlyPicker from 'vue-monthly-picker'
import monthLabels from '@/helpers/MonthLabels'

export default {
  components: {
    BCol,
    BButton,
    BFormRow,
    VueAutosuggest,
    VueMonthlyPicker,
  },
  data() {
    return {
      /**
       * For seller searcher
       */
      sellersOptions: [],

      monthLabels,
      /**
       * Set 2021 as min selectable date
       */
      minDate: this.$moment('2021-01-01'),
    }
  },
  computed: {
    ...mapGetters({
      getSellersSuggests: 'commissions/getSellersSuggests',
      getSelectedCommissionSeller: 'commissions/getSelectedCommissionSeller',
      getSelectedCommissionMonthAndYear: 'commissions/getSelectedCommissionMonthAndYear',
    }),
    /**
     * Lista de vendedores sugeridos
     */
    sellersSuggests: {
      get() { return this.getSellersSuggests },
    },
    /**
     * Filtro de creador (filtro en store)
     */
    filteredSeller: {
      get() { return this.getSelectedCommissionSeller },
      set(value) { this.setSelectedCommissionSeller(value) },
    },
    /**
     * Filtro de mes y año (filtro en store)
     */
    filteredMonthAndYear: {
      get() { return this.getSelectedCommissionMonthAndYear },
      set(value) { this.setSelectedCommissionMonthAndYear(value) },
    },
    sellerName: {
      get() { return this.filteredCreator?.name || '' },
      set() {},
    },
  },
  methods: {
    ...mapActions({
      setSelectedCommissionSeller: 'commissions/setSelectedCommissionSeller',
      setSelectedCommissionMonthAndYear: 'commissions/setSelectedCommissionMonthAndYear',
    }),
    /**
    * Evento de cambio de nombre de vendedor
    * @summary Emite un evento cuando se ingresan caracteres en el buscador de vendedor
    * @param {String} text - Término de búsqueda
    */
    onSellerNameChange(text) {
      if (text === '' || text === undefined) {
        this.sellersOptions = []
        this.filteredSeller = null
        return
      }

      const filteredData = this.sellersSuggests
        .filter(item => `${item.Name} ${item?.LastName}`.toLowerCase().indexOf(text.toLowerCase()) > -1)
        .slice(0, this.limit)

      const namedSuggests = []

      filteredData.forEach(item => {
        namedSuggests.push({ name: `${item.Name}`, id: item.IdUser, email: item.email })
      })
      this.sellersOptions = [{
        data: namedSuggests,
      }]
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
