<template>
  <b-card-body class="py-0 text-center">
    <div class="my-1">
      <avatar
        :inline="true"
        :src="require('@/assets/images/avatars/user-av-gray.png')"
        :size="60"
      />
    </div>
    <h4>Seleeciona un vendedor para ver sus órdenes y devoluciones</h4>
  </b-card-body>
</template>

<script>
import { BCardBody } from 'bootstrap-vue'
import Avatar from 'vue-avatar'

export default {
  components: {
    Avatar,
    BCardBody,
  },
}
</script>
